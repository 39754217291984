let hostName: any = 'ppp';

export const environment = {
  production: true,

  UsuariosWS: `${hostName}/api/Usuarios`,
  SalasWS: `${hostName}/api/Salas`,
  AssociadosWS: `${hostName}/api/Associados`,
  AssociadosDispWS: `${hostName}/api/AssociadoDisponibilidade`,
  AssociadosMensWS: `${hostName}/api/AssociadoMensalidade`,
  AreaAtuacaoWS: `${hostName}/api/AreaAtuacao`,
  CategoriaWS: `${hostName}/api/Categoria`,
  ListaWS: `${hostName}/api/ListasFixas`,
  RelatoriosRotinasWS: `${hostName}/api/RelatoriosRotinas`,
  PrevisaoWS: `${hostName}/api/previsao`,
  PlanoContaWS: `${hostName}/api/planoconta`,
  EnsinoParticipanteWS: `${hostName}/api/EnsinoParticipante`,

  needsBaseAuth: new Map<string, string[]>(
    Object.entries({
      [hostName + '/api/Usuarios/*']: ['*'],
    })
  ),

  needsAuthAplic: new Map<string, string[]>(
    Object.entries({
      [hostName + '/api/Salas/*']: ['*'],
      [hostName + '/api/Associados/*']: ['*'],
      [hostName + '/api/AssociadoDisponibilidade/*']: ['*'],
      [hostName + '/api/AssociadoMensalidade/*']: ['*'],
      [hostName + '/api/AreaAtuacao/*']: ['*'],
      [hostName + '/api/Categoria/*']: ['*'],
      [hostName + '/api/ListasFixas/*']: ['*'],
      [hostName + '/api/RelatoriosRotinas/*']: ['*'],
      [hostName + '/api/previsao/*']: ['*'],
      [hostName + '/api/planoconta/*']: ['*'],
      [hostName + '/api/EnsinoParticipante/*']: ['*'],
    })
  ),
};
